import React from 'react';
import { graphql } from 'gatsby';

import { BaseTextContentWithMedia, BaseTextContentWithMediaProps } from './base-component';

export type HomepageTextContentWithMediaProps = Pick<
  BaseTextContentWithMediaProps,
  'media' | 'contentOrder' | 'cta' | 'title' | 'backgroundColor' | 'markdown' | 'textAlign'
>;

export const HomepageTextContentWithMedia: React.FC<HomepageTextContentWithMediaProps> = props => (
  <BaseTextContentWithMedia {...props} headerSize="regular" />
);

export const contentImageFragment = graphql`
  fragment HomepageTextContentWithMedia on cms_ComponentHomepageTextWithImage {
    __typename
    title
    order
    link {
      ...Link
    }
    media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    media_mobile {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    youtube_embed_id
    markdown
    background_color
    text_align
  }
`;
