import React from 'react';
import { graphql } from 'gatsby';

import { BaseTextContentWithMedia, BaseTextContentWithMediaProps } from './base-component';

export type TextContentWithMediaProps = Pick<
  BaseTextContentWithMediaProps,
  'media' | 'contentOrder' | 'title' | 'cta' | 'text' | 'scroll' | 'markdown'
>;

export const TextContentWithMedia: React.FC<TextContentWithMediaProps> = props => (
  <BaseTextContentWithMedia {...props} backgroundColor="none" headerSize="regular" />
);

export const scrollableContentImageFragment = graphql`
  fragment ScrollableContentMedia on cms_ComponentGovernanceTextCard {
    title
    text
    media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    mobile_media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    youtube_embed_id
  }

  fragment TextContentWithMedia on cms_ComponentComponentTextWithImage {
    __typename
    title
    text
    order
    link {
      ...Link
    }
    media {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    media_mobile {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    youtube_embed_id
  }
`;
