import React from 'react';
import { BaseTextContentWithMedia, BaseTextContentWithMediaProps } from './base-component';

export type DonateTextContentWithMediaProps = Pick<
  BaseTextContentWithMediaProps,
  'media' | 'contentOrder' | 'title' | 'markdown'
>;

export const DonateTextContentWithMedia: React.FC<DonateTextContentWithMediaProps> = props => (
  <BaseTextContentWithMedia {...props} textAlign="left" backgroundColor="none" headerSize="regular" />
);
