import React from 'react';
import { BaseTextContentWithMedia, BaseTextContentWithMediaProps } from './base-component';

export type ProgramsTextContentWithMediaProps = Pick<
  BaseTextContentWithMediaProps,
  'media' | 'contentOrder' | 'text' | 'title' | 'textAlign' | 'backgroundColor' | 'markdown' | 'backgroundColor'
>;

export const ProgramsTextContentWithMedia: React.FC<ProgramsTextContentWithMediaProps> = props => (
  <BaseTextContentWithMedia {...props} headerSize="large" />
);
