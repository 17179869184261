import React from 'react';
import styled from '@emotion/styled';
import { Image, ImageProps } from '../atoms/image';
import { YoutubeEmbed, YoutubeEmbedProps } from '../atoms/youtube-embed';

type DisplayMode = 'fluid' | 'fixed';

type ImageMedia = {
  type: 'image';
  image?: ImageProps;
};

type YoutubeMedia = {
  type: 'youtube';
  youtube: YoutubeEmbedProps;
  displayMode?: DisplayMode;
  keepMediaRatio?: boolean;
};

export type MediaProps = ImageMedia | YoutubeMedia;

const YoutubeWrapper = styled.div<Pick<YoutubeMedia, 'displayMode' | 'keepMediaRatio'> & { mediaRatio: number }>`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: auto;

  ${({ keepMediaRatio, mediaRatio, theme }) => ` 
    @media screen and (min-width: ${theme.breakpoint.desktop}) {
      height: ${keepMediaRatio ? '0' : '100%'};
      ${keepMediaRatio && `padding-bottom: ${mediaRatio}%;`}
    }
  `}

  ${({ displayMode, theme }) =>
    displayMode === 'fluid' &&
    `
    @media screen and (min-width: ${theme.breakpoint.desktop}) {
        min-height: 0;
        margin: auto;
    `}
`;

export const Media: React.FC<MediaProps> = props => {
  const mediaRatio = 56.25; // 16:9 Youtube ratio

  switch (props.type) {
    case 'image':
      return <Image {...props.image} />;

    case 'youtube':
      return (
        <YoutubeWrapper displayMode={props.displayMode} keepMediaRatio={props.keepMediaRatio} mediaRatio={mediaRatio}>
          <YoutubeEmbed {...props.youtube} displayMode={props.displayMode} keepMediaRatio={props.keepMediaRatio} />
        </YoutubeWrapper>
      );
  }
};

Media.defaultProps = {
  displayMode: 'fluid',
};
