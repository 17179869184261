import React from 'react';
import styled from '@emotion/styled';

import { CTA, CtaProps } from '../cta';
import { Heading } from '../../atoms/heading';
import { AlignedMarkdown, AlignedMarkdownProps } from '../../atoms/aligned-markdown';
import { Media, MediaProps } from '../media';
import { ScrollHint } from '../../atoms/scroll-hint';

export type BaseTextContentWithMediaProps = {
  media?: MediaProps;
  contentOrder?: 'text_image' | 'image_text';
  backgroundColor: 'none' | 'purple';
  headerSize: 'regular' | 'large';
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  title?: string;
  text?: string;
  markdown?: string;
  alignedMarkdown?: AlignedMarkdownProps;
  cta?: CtaProps;
  scroll?: boolean;
  horizontalCenter?: boolean;
};

const Wrapper = styled.div<Pick<BaseTextContentWithMediaProps, 'backgroundColor' | 'contentOrder' | 'textAlign'>>`
  position: relative;
  display: flex;

  flex-direction: ${({ contentOrder }) => (contentOrder === 'text_image' ? 'row' : 'row-reverse')};

  ${({ textAlign }) =>
    textAlign === 'center' &&
    `
        justify-content: center;
        align-items: center;
      `}

  ${({ backgroundColor, theme }) =>
    backgroundColor === 'purple' && `background: ${theme.color.palette['base-purple']}`};

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column-reverse;
  }
`;

const TextContent = styled.div<Pick<BaseTextContentWithMediaProps, 'headerSize' | 'horizontalCenter'>>`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${({ horizontalCenter }) => (horizontalCenter ? `align-items: center;` : '')}

  margin: ${({ headerSize }) => (headerSize === 'regular' ? '50px' : '70px')} 25px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: ${({ headerSize }) => (headerSize === 'regular' ? '50px' : '70px')} 50px;
    text-align: left;
  }
`;

const Scrollable = styled.div<Pick<BaseTextContentWithMediaProps, 'scroll'>>`
  flex-basis: 50%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    ${({ scroll }) =>
      scroll &&
      `
        max-height: 550px;
        overflow-y: auto;

        scrollbar-width: none;

        ::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
    `}
  }
`;

export const BaseTextContentWithMedia: React.FC<BaseTextContentWithMediaProps> = props => {
  const centerMedia = props?.media?.type === 'youtube' && props.media.keepMediaRatio;

  return (
    <Wrapper backgroundColor={props.backgroundColor} contentOrder={props.contentOrder} textAlign={props.textAlign}>
      <Scrollable scroll={props.scroll}>
        <TextContent headerSize={props.headerSize} horizontalCenter={props.horizontalCenter}>
          {getTextContent(props)}
        </TextContent>
        {props.scroll && <ScrollHint css={{ width: '50%', height: '100px', position: 'absolute' }} />}
      </Scrollable>
      <div
        css={{
          flexBasis: '50%',
          width: '100%',
          alignSelf: 'stretch',
          ...(centerMedia && { margin: 'auto' }),
        }}
      >
        {props.media && <Media {...props.media} />}
      </div>
    </Wrapper>
  );
};

BaseTextContentWithMedia.defaultProps = {
  contentOrder: 'text_image',
};

function getTextContent(props: BaseTextContentWithMediaProps) {
  if (props.alignedMarkdown?.markdown) {
    return <AlignedMarkdown {...props.alignedMarkdown} />;
  }

  if (props.markdown) {
    return (
      <>
        {props.title && <Heading level={props.headerSize === 'regular' ? 'h2' : 'h1'} text={props.title} />}
        {props.markdown && <AlignedMarkdown markdown={props.markdown} align={props.textAlign} />}
        {props.cta && <CTA css={{ flex: '0 0 auto' }} {...props.cta} />}
      </>
    );
  }

  return (
    <>
      {props.title && <Heading level={props.headerSize === 'regular' ? 'h2' : 'h1'} text={props.title} />}
      {props.text && <p css={{ marginBottom: '40px' }}>{props.text}</p>}
      {props.cta && <CTA css={{ flex: '0 0 auto' }} {...props.cta} />}
    </>
  );
}
